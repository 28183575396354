import React from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { Card } from "./Layout"

const Meta = styled.div`
  overflow: hidden;
  flex-basis: 40%;
  position: relative;
`

const Image = styled.div`
  transition: transform 0.3s;
`

const Details = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  color: white;
  padding: 10px;
`

export const Teaser = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    ${Image} {
      transform: scale(1.3) rotate(3deg);
    }
    ${Details} {
      left: 0%;
    }
  }
  overflow: hidden;
  @media (min-width: 580px) {
    flex-direction: ${props => (props.vertical ? "column" : "row")};
  }
`

const Intro = styled.div`
  flex-basis: 60%;
  padding: 1rem;
  font-size: 0.85rem;
`

export default ({
  node: {
    data: { image, title, summary },
    uid,
  },
  vertical,
  className,
}) => {
  return (
    <Card className={className}>
      <Teaser vertical={vertical}>
        <Meta>
          <Image>
            {image.localFile && (
              <Img fluid={image.localFile.childImageSharp.fluid} />
            )}
          </Image>
          <Details>Hallo!</Details>
        </Meta>
        <Intro>
          <h2>{title.text}</h2>
          <p>{summary.text}</p>
          <Link to={uid}>Read</Link>
        </Intro>
      </Teaser>
    </Card>
  )
}

export const blogTeaserFragment = graphql`
  fragment BlogTeaser on PrismicBlogpost {
    uid
    data {
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 230) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      title {
        text
      }
      summary {
        text
      }
    }
  }
`
